import React, { useState, useEffect } from "react";
import { navigate } from "gatsby-link";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TopTitleBar from "../components/TopTitleBar";

const ThankYouStyles = styled.main`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 8rem 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  h1,
  p {
    margin-bottom: 0.5em;
  }
`;

const Lease = () => {
  const [counter, setCounter] = useState(8);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    counter === 0 && navigate("/");
  }, [counter]);

  return (
    <Layout>
      <Seo title="Thank You!" />
      <TopTitleBar title="Thank You!" />
      <ThankYouStyles>
        <h1>Thank you for contacting us!</h1>
        <p>
          We will promptly respond to your inquiry as we frequently check our
          e-mail.
        </p>
        <p>
          You will be redirected back to the homepage in <b>{counter}</b>
        </p>
      </ThankYouStyles>
    </Layout>
  );
};

export default Lease;
