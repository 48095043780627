import React from "react";
import styled from "styled-components";
import cornerLeft from "../assets/corner-bot-left-properties.svg";

const TopTitleBarStyles = styled.div`
  position: relative;
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  background: var(--color-black);
  padding: 2em 0;
  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .title-wrapper {
    grid-column: 2 / span 12;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
    }
    h1 {
      color: var(--color-white);
      margin: 0;
    }
  }

  .corner-left {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5rem;
    z-index: 0;
  }
`;

const TopTitleBar = ({ title }) => {
  return (
    <TopTitleBarStyles>
      <div className="title-wrapper">
        <h1>{title}</h1>
      </div>
      <img src={cornerLeft} alt="corner left" className="corner-left" />
    </TopTitleBarStyles>
  );
};

export default TopTitleBar;
